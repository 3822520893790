#html-body .spotlight-section[data-content-type="row"] {
    @apply py-[40px] md:pt-[160px] md:pb-[135px];
    [data-content-type="text"] {
        @apply mb-[30px];
    }
    h2 {
        @apply mb-[30px];
    }
    img {
        @apply mx-auto mb-[30px];
    }
}
