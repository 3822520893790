.catalogsearch-result-index {
    .page-wrapper {
        @apply pt-[65px] md:pt-[150px];
    }
    .columns {
        @apply pt-[30px] pb-[30px] md:pt-[100px] md:pb-[150px];
    }
    .top-container {
        @apply container;
    }
}
