/* Rows */
.page-layout-cms-full-width-sm {
    .top-container,
    .page-title-wrapper {
        @apply container lg:max-w-[960px];
    }
    [data-content-type='row'] {

        &[data-appearance="full-bleed"] {
            @apply px-5;
        }

        &[data-appearance='contained'] {
            @apply lg:max-w-[960px];
        }

        &[data-appearance='full-width'] {
            > .row-full-width-inner {
                @apply lg:max-w-[960px];
            }
        }
    }

    .column.main {
        h1 {
            @apply mb-[40px];
        }
        h2, h3, h4 {
            @apply mb-[24px];
        }
        h4 {
            @apply normal-case;
        }
        p {
            @apply mb-[24px];
        }
    }
}
.catalog-category-view {
    .category-banner-content {
        [data-content-type='row'] {
            &[data-appearance='contained'] {
                @apply pl-[0];
                [data-element='inner'] {
                    @apply !pl-[0];
                }
            }
        }
    }
}
