.header-open {
    @apply overflow-hidden;
}
#header {
    .cms-home & {
        &:not(.header--stuck, .dropdown--open) {
            .masthead {
                @apply bg-gradient-to-charcoal text-alabaster hover:bg-none hover:text-blue-charcoal;
            }
        }
    }
    .catalog-category-view & {
        &:not(.header--stuck, .dropdown--open) {
            .masthead {
                @apply max-md:text-alabaster max-md:hover:text-blue-charcoal;
            }
        }
    }
    .cms-promo & {
        &:not(.header--stuck, .dropdown--open) {
            .masthead {
                @apply max-md:text-alabaster max-md:hover:text-blue-charcoal;
            }
        }
    }
    &.dropdown--open {
        @apply h-[90vh] overflow-y-scroll w-screen;
        .masthead {
            @apply bg-none bg-gallery;
        }
        &.search-open {
            .masthead {
                @apply bg-blue-charcoal;
            }
        }
        .header-container {
            @apply h-full
        }
        .header-content {
            @apply flex flex-col overflow-hidden max-h-full
        }
    }
    &:not(.dropdown--open) {
        .masthead {
            @apply hover:text-blue-charcoal;
        }
    }
    &.dropdown--open {
        &.search-open {
            @apply text-alabaster;
            .header__expanded-area {
                @apply bg-blue-charcoal;
            }
        }
    }
}

.header--stuck {
    &:not(.dropdown--open) {
        .header-col-1 {
            .logo {
                svg {
                    @apply max-h-full;
                }
            }
        }
    }
}

.catalogsearch-result-index,
.cms-noroute-index,
.contact-index-index,
.storelocator-index-index,
.page-layout-cms-full-width-sm,
.catalog-product-view {
    .header-col-1 {
        @media (max-width: 1720px) {
            @apply overflow-hidden;
        }
    }
}

.header__expanded-container {
    @apply max-w-[960px] px-5 mx-auto w-full;
    .open-desktop-sub-menu & {
        @apply max-w-[1560px];
        & > div {
            @apply md:px-[15px] lg:px-[60px];
        }
    }
}
.switcher-language-open {
    .header__expanded-container {
        @apply max-w-[1200px];
    }
}
.header-content {
    &:has(.container-sm.open) {
        .header__expanded-container {
            .open-desktop-sub-menu & {
                @apply max-w-[1080px];
            }
        }
    }
}

.mini-search {
    input[type="search"]::-webkit-search-cancel-button {
        -webkit-appearance: none;
        height: 36px;
        width: 36px;
        background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fafafa"><path d="M18.3 5.7a1 1 0 0 0-1.4 0L12 10.6 7.1 5.7a1 1 0 1 0-1.4 1.4L10.6 12l-4.9 4.9a1 1 0 0 0 1.4 1.4l4.9-4.9 4.9 4.9a1 1 0 0 0 1.4-1.4L13.4 12l4.9-4.9a1 1 0 0 0 0-1.4z"/></svg>') no-repeat center center;
        background-size: contain;
        cursor: pointer;
    }
}
