@layer utilities {
    /* This class only exists for backwards compatibility, */
    /* The default is applied in tailwind.config.js since Hyvä 1.2.0 */
    .text-sans-serif {
        font-family: "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    }
}

html {
    @apply antialiased;
}

body {
    @apply text-blue-charcoal leading-relaxed text-base tracking-[.5px];
}
