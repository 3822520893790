#html-body .preview-section[data-content-type="row"] {
    @apply pt-[20px] pb-[50px] md:pt-[160px] md:pb-[145px] text-alabaster;
    .row-full-width-inner {
        @apply max-w-[960px];
    }
    [data-content-type="html"] {
        @apply flex px-[1.5px] mb-1.5;
        svg {
            @apply w-[14px] h-[16px] mx-[-1.5px];
        }
    }
    .pagebuilder-column {
        @apply mb-[30px];
        [data-content-type="text"] {
            @apply  mb-[20px];
        }
        h1, h2, h5 {
            @apply  mb-[20px] md:mb-[25px];
        }
    }
    h1, h2, h5 {
        @apply  mb-[20px] md:mb-[70px];
    }
    h3 {
        @apply  mb-[20px];
    }
    [data-content-type="image"] {
        @apply  mb-[30px];
    }
    img {
        @apply max-w-none w-full;
    }
}
