.switcher-list {
    @apply flex flex-wrap p-0 my-0 list-none list-image-none mx-[-20px];
    li {
        @apply flex-[0_0_100%] max-w-[100%] md:flex-[0_0_33.33333%] md:max-w-[33.33333%] px-[20px] mb-[10px];
        &.active {
            @apply text-flush-mahogany;
        }
    }
    a {
        @apply text-blue-charcoal no-underline hover:underline;
    }
}
