.account-nav li {
    & a,
    & strong {
        @apply flex justify-between text-secondary py-1;
    }

    & a:hover {
        @apply text-black;
    }

    & strong {
        @apply underline text-black font-normal;
    }
}

.actions-toolbar {
    @apply mt-6 border-t border-container-darker pt-4 flex justify-between flex-row-reverse items-center;

    & a.back {
        @apply text-secondary-darker underline;
    }

    @layer components {
        & .primary button {
            @apply btn btn-primary;
        }
    }
}
