.snap {
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    & > div {
        scroll-snap-align: start;
    }
}
