.category-banner {
    @apply md:flex md:flex-col md:items-stretch lg:min-h-[625px] md:py-[80px] bg-gallery md:relative;
    &.news-banner {
        @apply md:py-[0];
    }
}
.innovations-index-post {
    .category-banner {
        /*@apply min-h-screen;*/
    }
}
.category-banner-media {
    @apply relative md:absolute md:h-full md:left-0 md:top-0 md:w-[calc(40%+100px)] lg:w-[calc(45.5%+140px)] md:flex-[1_1_auto]
    md:before:content-[''] md:before:absolute md:before:z-20 md:before:top-0 md:before:right-0 md:before:w-[100px] lg:before:w-[260px] md:before:h-full md:before:bg-triangale-grey-light-lg md:before:bg-no-repeat md:before:bg-[length:100%_100%]
    max-md:before:hidden
    after:content-[''] after:absolute after:z-10 after:inset-0 after:bg-gradient-to-b after:from-[rgba(8,16,26,0.6)];
    img {
        @apply md:absolute md:h-full w-full md:left-0 md:top-0 max-w-none md:object-cover md:object-center max-md:min-h-[320px] max-md:object-cover;
    }
}
.category-banner-content {
    @apply max-md:pt-[58px] max-md:pb-[30px] md:relative md:z-20 md:flex-[1_1_auto] md:flex md:flex-col md:items-stretch;
    .container {
        @apply md:flex-[1_1_auto] md:flex md:flex-col md:items-stretch md:justify-center;
    }
    .category-banner-row {
        @apply md:w-[60%] lg:w-[54.5%] md:ml-auto md:pl-[100px] lg:pl-[205px];
    }
    .category-description {
        @apply font-bold;
        [data-content-type=html] {
            @apply font-bold;
            * {
                @apply font-normal;
            }
            p {
                @apply font-bold;
            }
        }
        * {
            @apply font-normal;
        }
        p {
            @apply font-bold max-md:text-[14px];
        }
    }
}

.catalog-category-view {
    .columns {
        @apply py-[80px] md:py-[150px];
    }
}
.am-filter-tooltip {
    @apply hidden;
}

.products {
    .product-info {
        .price-box {
            @apply hidden;
        }
        [data-bv-show=inline_rating] {
            .bv_main_container {
                .bv_text {
                    @apply !font-sans;
                }
                .bv_averageRating_component_container {
                    .bv_text {
                        @apply !font-bold;
                    }
                }
            }
            .bv_incentivized_review_container {
                @apply !hidden;
            }
        }
    }
}

