.catalog-product-view {
    .page-title {
        @apply m-0;
    }
    pre {
        @apply whitespace-normal font-sans text-base;
    }
    .description {
        br {
            @apply hidden;
        }
    }
}

.product-uploads {
    h1,h2,h3,h4,h5,h6 {
        @apply normal-case;
    }
}

#html-body.catalog-product-view .product-info-main > [data-content-type="row"], #html-body.catalog-product-view .product-info-main .pwrcore-product-view > [data-content-type="row"] {
    @apply pb-[40px] md:pb-[160px];
    img {
        @apply max-w-none w-full mb-[30px];
    }
    [data-content-type="text"] {
        @apply mb-[30px];
    }
}

.product-slider {
    .product-info {
        .price-box {
            @apply hidden;
        }
        [data-bv-show=inline_rating] {
            .bv_main_container {
                .bv_text {
                    @apply !font-sans;
                }
                .bv_averageRating_component_container {
                    .bv_text {
                        @apply !font-bold;
                    }
                }
            }
            .bv_incentivized_review_container {
                @apply !hidden;
            }
        }
    }
}

.product-info-main {
    [data-bv-show="rating_summary"] {
        @apply !pt-[10px];
        .bv_incentivized_component_container {
            @apply !hidden;
        }
        .bv_main_container_row_normal {
            &:has(.bv_incentivized_component_container) {
                @apply !hidden;
            }
        }
    }
}
