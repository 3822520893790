#html-body .demo-section[data-content-type="row"] {
    @apply py-[80px] md:pt-[160px] md:pb-[100px];
    .row-full-width-inner {
        @apply max-w-[1200px];
    }
    .pagebuilder-column-group {
        @apply mt-[40px];
    }
    .pagebuilder-column {
        &:first-child {
            [data-content-type="image"] {
                @apply max-md:-mx-5;
            }
            img {
                @apply max-md:max-w-none max-md:w-full;
            }
        }
        &:last-child:not(:only-child) {
            @apply md:order-first max-md:items-center max-md:text-center;
            [data-content-type="image"] {
                @apply mb-[25px];
            }
            h4 {
                @apply mb-[30px];
            }
            [data-content-type="text"] {
                @apply mb-[30px];
            }
        }
    }
}
