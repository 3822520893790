#html-body {
    .newsletter-row {
        @apply md:flex md:flex-col md:items-stretch md:min-h-[642px] bg-nevada md:relative text-alabaster;
        h2 {
            @apply mb-[15px] md:mb-[35px];
        }
        p {
            strong {
                @apply block mb-[15px] md:mb-[35px];
            }
        }
    }
    .newsletter-media {
        @apply relative md:absolute md:h-full md:left-0 md:top-0 md:w-[calc(50%+100px)] md:flex-[1_1_auto]
        md:before:content-[''] md:before:absolute md:before:z-10 md:before:top-0 md:before:right-0 md:before:w-[146px] md:before:h-full md:before:bg-triangale-grey-lg md:before:bg-no-repeat md:before:bg-[length:100%_100%]
        max-md:before:content-[''] max-md:before:absolute max-md:before:z-10 max-md:before:bottom-0 max-md:before:left-0 max-md:before:w-full max-md:before:h-[75px] max-md:before:bg-triangale-grey-sm max-md:before:bg-no-repeat max-md:before:bg-[length:100%_100%]
        ;
        img {
            @apply md:absolute md:h-full w-full md:left-0 md:top-0 max-w-none md:object-cover md:object-center;
        }
    }
    .newsletter-content {
        @apply md:relative md:z-10 md:flex-[1_1_auto] md:flex md:flex-col md:items-stretch md:py-[30px] max-md:pt-[10px] max-md:pb-[80px];
        .container {
            @apply md:flex-[1_1_auto] md:flex md:flex-col md:items-stretch md:justify-center;
        }
        .newsletter-content-row {
            @apply md:w-[50%] md:ml-auto md:pl-[100px];
        }
    }
}
