.cms-noroute-index {
    #maincontent {
        @apply pt-[100px] pb-[30px] md:pt-[240px] md:pb-[120px];
    }
    [data-content-type="text"] {
        @apply max-w-[920px] mx-auto;
    }
    .column {
        &.main {
            [data-content-type='row'] {
                picture {
                    img {
                        @apply inline-block mb-[40px] md:mb-[70px] mix-blend-multiply;
                    }
                }
                h1 {
                    @apply mb-[20px];
                }
                [data-content-type='buttons'] {
                    @apply mt-[40px];
                }
            }
        }
    }
}
.contact-index-index {
    .page-wrapper {
        @apply pt-[65px] md:pt-[150px];
    }
}
.cms-page-view {
    &:not(.page-layout-cms-full-width-without-breadcrumbs-and-spacing, .page-layout-cms-full-width-pwrcore) {
        .page-wrapper {
            @apply pt-[65px] md:pt-[150px];
        }
    }
}

.cms-warranty {
    .column.main  {
        @apply md:pb-[80px];
    }
}
