#html-body .promo-section[data-content-type="row"] {
    @apply pt-[20px] pb-[5px] md:pt-[160px] md:pb-[145px] max-md:text-center text-alabaster;
    .row-full-width-inner {
        @apply max-md:max-w-none max-md:px-0;
    }
    .pagebuilder-column {
        &:first-child {
            img {
                @apply  max-md:max-w-none max-md:w-full;
            }
        }
        &:last-child:not(:only-child) {
            @apply md:py-[80px] max-md:px-[20px] max-md:pb-[80px] max-md:pt-[20px] md:pr-[40px] max-md:items-center;
            [data-content-type="image"] {
                @apply mb-[30px] md:mb-[25px];
            }
            h2 {
                @apply mb-[30px] md:mb-[25px];
            }
        }
    }
    h4 {
        @apply max-md:text-sm;
    }
    p {
        @apply max-md:text-sm;
    }
    .tabs-navigation {
        @apply flex gap-[8px] p-0 m-0 mt-[5px] md:mt-[15px] list-none list-image-none shadow-none max-md:justify-center;
        .tab-header {
            @apply flex p-0 m-0 border-0 bg-inherit;
            > .tab-title {
                @apply min-w-[75px] text-center p-[10px] m-0 font-family__heading-secondary border-[1px] border-nevada bg-inherit text-persian-red text-[28px] uppercase font-medium;
                span {
                    @apply text-persian-red;
                }
            }
            &.active {
                > .tab-title {
                    @apply border-alabaster text-alabaster;
                    span {
                        @apply text-alabaster;
                    }
                }
            }
        }
    }
    .tabs-content {
        @apply min-h-0 border-0 p-0 m-0 mt-[30px] md:mt-[15px] rounded-none bg-inherit shadow-none;
        [data-content-type="tab-item"] {
            @apply p-0 m-0;
        }
        h4 {
            @apply mb-[5px] md:mb-[15px];
        }
        [data-content-type="text"] {
            @apply mb-[30px] md:mb-[25px];
        }
    }
}
