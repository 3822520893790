.innovations-index-index {
    word-break: auto-phrase;
    .category-banner {
        .category-banner-col {
            &.category-banner-media {
                @apply z-[41];
            }
        }
        h1 {
            strong {
                @apply font-normal text-flush-mahogany;
            }
        }
        p {
            @apply mt-[30px] font-[600];
            word-break: auto-phrase;
        }
    }
    .columns {
        @apply gap-0;
    }
    .current-innovations {
        @apply font-normal bg-blue-charcoal pb-[10px] md:pb-[196px];
        ul {
            @apply gap-[40px];
        }
        h2 {
            @apply text-alabaster mt-[80px] md:mt-[160px] mb-[24px] max-md:text-center;
        }
        .main-img {
            @apply h-[290px];
            img {
                    @apply block w-[100%] object-cover h-[290px];
            }
        }

        .inner {
            @apply text-alabaster py-[16px] md:p-[16px];
            a {
                @apply text-alabaster pt-[16px] no-underline uppercase block;
                svg {
                    @apply text-alabaster;
                }
            }
        }
    }
    .historic-innovations {
        @apply font-normal pb-[196px];
        ul {
            @apply gap-[40px];
        }
        img {
            @apply block w-[100%];
        }
        @apply p-[16px];
        .year {
            @apply text-flush-mahogany mt-[30px];
        }
        &.news-related {
            .main-img {
                @apply h-[290px];
                img {
                    @apply block w-[100%] object-cover h-[290px];
                }
            }
        }
    }
    .historic-innovations:not(.news-related) {
        h2 {
            @apply mt-[65px] md:mt-[160px] mb-[24px];
        }
    }
}
.innovations-index-post {
    .category-banner {
        .category-banner-col {
            &.category-banner-media {
                @apply z-[41];
            }
        }
        h1 {
            strong {
                @apply font-normal text-[#03A4DA];
            }
            sup {
                @apply text-[0.2em] top-[-3em];
            }
        }
        p {
            @apply mt-[30px] font-[600];
            word-break: auto-phrase;
        }
        .pagebuilder-button-default {
            @apply whitespace-nowrap mt-[30px];
            svg {
                @apply text-inherit;
            }
        }
    }
    .cms-page-view {
        [data-content-type='row'] {
            @apply md:mb-[40px] mb-[30px];
            &[data-appearance='contained'], &[data-appearance='full-width'] {
                @apply p-[0px] md:p-[160px];
            }
            &:not(.video) {
                @apply pt-[0] pb-[0];
            }
            h1 {
                @apply md:mt-[160px] md:mb-[40px] mt-[80px] mb-[30px];
                strong {
                    @apply text-flush-mahogany;
                }
            }
            p {
                @apply mb-[30px];
            }
            .pagebuilder-column-group {
                &:last-child {
                    h4 {
                        @apply md:mt-[24px] mb-[8px] mt-[16px];
                    }
                    img {
                        @apply md:mt-[0] md:mb-[0] mt-[0] mb-[0] w-full md:w-auto;
                    }
                }
            }
            #showmore {
                @apply mt-[160px];
                .pagebuilder-column-group {
                    &:last-child {
                        @apply text-left;
                    }
                }
            }
            > .pagebuilder-column-group:first-child {
                [data-content-type="text"]:first-child {
                    > h2:first-child {
                        @apply md:mt-[160px] md:mb-[40px] mt-[80px] mb-[30px];
                    }
                    > h3:first-child {
                        @apply md:mt-[160px] md:mb-[40px] mt-[80px] mb-[30px];
                    }
                }
            }
        }
        [data-content-type=image] {
            .pagebuilder-mobile-hidden {
                display: inline-block;
            }
        }
        .more-content {
            height: 133px;
        }
    }
}
.page-layout-cms-full-width-pwrcore {
    .page-main-full-width {
        p {
            @apply mt-[30px];
        }
        .pagebuilder-button-default {
            @apply whitespace-nowrap mt-[30px];
            svg {
                @apply text-inherit;
            }
        }
    }
    .products-title {
        @apply mt-[160px] mb-[40px];
    }
}
.hidden-count {
    .count {
        @apply hidden;
    }
}