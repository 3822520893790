@layer base {
    a[class*='pagebuilder-button'] {
        @apply no-underline;
    }
}

@layer components {
    .btn-primary {
        @apply uppercase inline-block;

        &:hover {
            @apply no-underline;
        }
    }
    .btn-secondary {
        @apply uppercase inline-block no-underline;

        &:hover {
            @apply no-underline;
        }
    }
    .btn-default {
        @apply btn py-[0.438rem] uppercase border-[0.063rem] text-nevada inline-block no-underline;

        &:hover {
            @apply border-default-hover text-blue-charcoal no-underline;
        }
    }
    .btn-default_white {
        @apply btn-default text-alabaster border-default-white no-underline;

        &:hover {
            @apply text-silver-sand border-default-white-hover no-underline;
        }
    }
}

a,
button,
div {
    &.pagebuilder-button-default {
        @apply btn btn-default;
    }
    &.pagebuilder-button-link {
        @apply uppercase text-[0.813rem] font-bold inline-block;
        @screen md {
            @apply text-[1.063rem];
        }
        svg {
            @apply inline-flex;
        }
    }
    .pagebuilder-button-link-grey {
        @apply pagebuilder-button-link text-nevada inline-block;
        &:hover {
            @apply text-silver-sand;
        }
    }
    .pagebuilder-button-link-white {
        @apply pagebuilder-button-link text-alabaster inline-block;
        &:hover {
            @apply text-silver-sand;
        }
    }
    &.pagebuilder-button-default-white {
        @apply btn-default_white;
    }
}
