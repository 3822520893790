.footer.content {
    @apply bg-blue-charcoal text-alabaster pt-[35px] md:pt-[80px] pb-[30px] md:pb-[90px] max-md:text-sm;
    ul {
        @apply list-reset-styles;
        li {
            @apply mb-6;
        }
    }
    a {
        @apply text-alabaster hover:no-underline;
    }
    h3, p {
        @apply text-lg text-silver-sand mb-5 max-md:text-sm;
    }
    .socials {
        h3, p {
            @apply text-alabaster;
        }
    }
}
.footer-group {
    @apply border-b-[1px] border-silver-sand pb-[5px] md:pb-[45px] mb-[50px] md:mb-[90px];
}
.footer-row {
    @apply flex flex-wrap mx-[-20px];
}
.footer-col {
    @apply flex-[0_0_100%] md:flex-[0_0_33.33333%] max-w-full w-full px-[20px] mb-[40px];
}
.footer-panel {
    ul {
        @apply md:flex md:gap-x-[45px] text-[11px] md:text-[13px];
    }
    .widget.block {
        @apply inline-block;
    }
}
