.page-product-bundle {
    .price-final_price {
        .price-from,
        .price-to {
            .price-container {
                @apply text-gray-700 block mb-4;

                .price-label {
                    @apply block text-lg font-medium;
                }

                .price {
                    @apply font-semibold text-2xl text-gray-800 leading-tight;
                }
            }

            .price-including-tax + .price-excluding-tax {
                @apply mt-1;

                .price {
                    @apply text-base;
                }
            }

            .old-price {
                .price-container {
                    .price,
                    .price-label {
                        @apply text-gray-500 font-normal inline text-base;
                    }
                }
            }
        }
    }
}
