@layer components {
    .btn {
        @apply px-2.5 py-2 text-alabaster text-[0.813rem] font-bold leading-7;

        @screen md {
            @apply px-6 text-[1.063rem];
        }
        @screen xl {
            @apply text-[1.063rem];
        }

        svg {
            @apply inline-flex ml-1.5 mt-[-3px];
        }

        span {
            display: contents;
        }

        &:hover {

        }

        &:focus {
            @apply outline-none border-transparent ring-4 ring-primary ring-opacity-50;
        }
    }

    .btn-primary {
        @apply bg-primary;

        &:hover {
            @apply bg-primary-lighter;
        }
    }

    .btn-secondary {
        @apply bg-secondary;

        &:hover {
            @apply bg-secondary-lighter;
        }

        &:focus {

        }
    }

    .btn-size-lg {
        @apply px-10 py-4 text-lg;
    }

    .btn-size-sm {
        @apply px-2 py-2 text-sm;
    }
}
