.product-item {
    .price-container {
        @apply block;

        .price {
            @apply font-semibold text-lg;
        }

        .price-label {
            @apply text-sm;
        }
    }

    .special-price .price-container .price-label {
        @apply sr-only;
    }

    .old-price .price-container {
        @apply text-gray-500;

        .price {
            @apply font-normal text-base;
        }
    }
}
