.stepbystep-index-index {
    .category-banner {
        .category-banner-col {
            &.category-banner-media {
                @apply z-[41];
            }
        }
    }
    .category-banner-row {
        h1 {
            strong {
                @apply text-flush-mahogany font-normal;
            }
            @apply mb-[30px];
        }
        p {
            @apply font-bold;
        }
    }
    .columns {
        h1 {
            @apply mt-[120px];
        }
        h2 {
            @apply mt-[32px] mb-[20px];
        }
        a {
            @apply text-blue-charcoal no-underline;
        }
    }
    hr {
        @apply block mt-[100px] mb-[20px];
    }
    .power {
        @apply mb-[170px];
        h1 {
            strong {
                @apply text-flush-mahogany font-normal;
            }
            @apply mb-[30px];
        }
        img {
            @apply m-auto
        }
        p, img {
            @apply mb-[30px];
        }
    }
}