/* fonts */
@font-face {
    font-display: swap;
    font-family: "Inter";
    src: url("../fonts/inter/inter-v18-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext-regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: "Inter";
    src: url("../fonts/inter/inter-v18-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext-500.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: "Inter";
    src: url("../fonts/inter/inter-v18-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext-500italic.woff2") format("woff2");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: "Inter";
    src: url("../fonts/inter/inter-v18-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext-600.woff2") format("woff2");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: "Inter";
    src: url("../fonts/inter/inter-v18-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext-600italic.woff2") format("woff2");
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: "Inter";
    src: url("../fonts/inter/inter-v18-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext-700.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: "Bebas Neue";
    src: url("../fonts/bebasneue/bebas-neue-v14-latin_latin-ext-regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: "Brixton_Lead Vector";
    src: url("../fonts/brixtonleadvector/brixton-lead-vector.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: "Gotham Condensed";
    src: url("../fonts/gothamcondensed/gotham-condensed-medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: "Sofia Sans Extra Condensed";
    src: url("../fonts/sofiasansextracondensed/sofia-sans-extra-condensed-v2-cyrillic_cyrillic-ext_greek_latin_latin-ext-700.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
}

@layer base {
    *,
    ::before,
    ::after {
        @apply break-words;
    }
    html {
        @apply overflow-y-scroll;
    }
    body, html {
        @apply min-h-full overflow-x-hidden;
    }
    h1,
    h2, .add-h2 {
        @apply font-heading uppercase;
    }
    :lang(hr) h1,
    :lang(hr) h2,
    :lang(bg) h1,
    :lang(bg) h2,
    :lang(rs) h1,
    :lang(rs) h2,
    :lang(gr) h1,
    :lang(gr) h2,
    :lang(uk) h1,
    :lang(uk) h2,
    :lang(hr) .add-h2,
    :lang(bg) .add-h2,
    :lang(rs) .add-h2,
    :lang(gr) .add-h2,
    :lang(uk) .add-h2    {
        @apply font-headingCyrillicGreek;
    }
    
    h1 {
        @apply uppercase text-5xl md:text-6xl leading-[0.9];
    }
    h2, .add-h2  {
        @apply uppercase text-3xl md:text-5xl leading-[0.9];
    }
    h3 {
        @apply uppercase text-2xl font-semibold;
    }
    h4 {
        @apply uppercase text-lg font-semibold;
    }
    h5 {
        @apply uppercase text-base font-semibold;
    }
    h6 {
        @apply uppercase text-base font-semibold;
    }
    a {
        @apply text-flush-mahogany underline hover:text-stiletto;
    }
    blockquote {
        @apply italic font-medium flex gap-x-1.5 before:content-[''] before:flex-[0_0_2.75rem] before:w-[2.75rem] before:h-[2rem] before:bg-quote before:bg-no-repeat before:bg-[length:100%_100%] after:content-[''] after:self-end after:flex-[0_0_2.75rem] after:w-[2.75rem] after:h-[2rem] after:bg-quote after:bg-no-repeat after:bg-contain;
    }
    ul {
        @apply list-image-markerGrey pl-[15px];
    }
    ul[style*="circle"] {
        @apply list-image-markerRed;
    }
    ol {
        @apply list-decimal pl-[15px];
    }
}

.cms-index-index, .pwrcore-product-view {
    h5 {
        @apply font-heading font-light uppercase text-5xl md:text-6xl leading-[0.9];
    }
}
.page-footer {
    .newsletter-panel {
        p {
            strong {
                @apply font-heading font-light uppercase text-3xl md:text-5xl leading-[0.9];
            }
        }
    }
}
:lang(hr),
:lang(hr),
:lang(bg),
:lang(bg),
:lang(rs),
:lang(rs),
:lang(gr),
:lang(gr),
:lang(uk),
:lang(uk) {
    .cms-index-index, .pwrcore-product-view {
        h5 {
            @apply font-headingCyrillicGreek;
        }
    }
    .newsletter-panel {
        p {
            strong {
                @apply font-headingCyrillicGreek;
            }
        }
    }
}

.page-wrapper {
    @apply flex flex-col min-h-screen;
}
.page-footer {
    @apply mt-auto;
}

.font-family__heading {
    @apply font-heading;
}

:lang(hr) .font-heading,
:lang(hr) .font-family__heading,
:lang(bg) .font-heading,
:lang(bg) .font-family__heading,
:lang(rs) .font-heading,
:lang(rs) .font-family__heading,
:lang(gr) .font-heading,
:lang(gr) .font-family__heading,
:lang(uk) .font-heading,
:lang(uk) .font-family__heading {
    @apply font-headingCyrillicGreek;
}

.font-family__heading-cyrillic-greek {
    @apply font-headingCyrillicGreek;
}

.font-family__heading-extra {
    @apply font-headingExtra;
}

.font-family__heading-secondary {
    @apply font-headingSecondary;
}

.list-reset-styles {
    @apply p-0 m-0 list-none list-image-none;
}
