.navigation-desktop {
    ul {
        @apply list-reset-styles;
    }

    a {
        @apply inline-flex uppercase no-underline text-sm lg:text-[17px] font-bold text-blue-charcoal hover:text-flush-mahogany;

        &.active {
            @apply text-flush-mahogany;
        }
    }

    .level-0 {
        svg {
            @apply w-[18px] mt-[-3px] lg:w-[24px] lg:mt-0;
        }
    }

    &.navigation-secondary-level {
        .submenus {
            @apply left-0 relative opacity-100 transition-[opacity,left] ease-in-out duration-700;
            &.slideout {
                @apply md:left-[-15px] lg:left-[-60px] opacity-0;
            }
            &.slidein {
                @apply md:left-[15px] lg:left-[60px] opacity-0;
            }
            &.transitions-off {
                @apply transition-none;
            }
        }
        ul:not(.first-col,.has-image) {
            a {
                @apply inline-block gap-1 mb-1.5;
            }
        }
        ul:not(.has-image) {
            a {
                @apply mb-1.5;
            }
        }
    }

    .first-col {
        @apply flex-[0_0_220px] 2xl:flex-[0_0_440px];

        a {
            @apply font-family__heading text-[24px] 2xl:text-[40px] font-normal leading-tight mb-0 hover:text-silver-sand;

            svg {
                @apply hidden;
            }
        }
    }

    .has-image {
        @apply flex flex-1 flex-wrap mx-[-20px] text-center content-start;

        > li {
            @apply flex-[0_0_25%] flex flex-col items-center mb-[40px] px-[20px];
        }

        .all-items {
            .view-all {
                @apply flex flex-col mt-0 items-center text-nevada hover:text-silver-sand;
                svg {
                    @apply block;
                }
                .icon-plus {
                    @apply flex flex-col justify-center h-[80px] mb-2.5
                }
            }
        }

        a {
            &:only-child {
                @apply mt-[90px];
            }
            &:last-child:not(:only-child) {
                @apply mb-0;
            }
            svg {
                @apply hidden;
            }
        }

        .media {
            > * {
                @apply h-full;
            }
            img {
                @apply h-full object-contain;
            }
        }
    }

    &.navigation-first-level {
        a {
            @apply flex gap-x-[8px] text-inherit no-underline uppercase font-bold hover:text-stiletto aria-[current=page]:text-flush-mahogany;

            &.open {
                @apply text-flush-mahogany;
            }
        }
    }

    .open-desktop-sub-menu & {
        @apply flex-1;
    }
}

.open-nav-mobile {
    .logo {
        @apply z-20 fixed;
    }
}

.navigation-mobile {
    ul {
        @apply list-none list-image-none;
    }
}
