#html-body .gallery-section[data-content-type="row"] {
    @apply py-[40px] md:pt-[160px] md:pb-[135px];
    .pagebuilder-column-group {
        @apply md:pt-[40px] mb-[30px];
    }
    .pagebuilder-column-line {
        @apply max-md:grid-cols-[1fr_1fr] max-md:grid-rows-[auto_auto] grid grid-cols-[2fr_1fr] grid-rows-[1fr_1fr] gap-1.5 md:gap-10;
    }
    .pagebuilder-column {
        @apply w-auto max-w-none;
        &:nth-child(1) {
            @apply max-md:col-[1/-1] max-md:row-[1] col-[1/2] row-[1/3];
        }
        &:nth-child(2) {
            @apply max-md:col-[1] max-md:row-[2] col-[2/3] row-[1/2];
        }
        &:nth-child(3) {
            @apply max-md:col-[2] max-md:row-[2] col-[2/3] row-[2/3];
        }
    }
    [data-content-type="image"] {
        @apply flex flex-auto overflow-hidden;
        img {
            @apply transition duration-300 ease-in w-full h-full max-w-none hover:scale-110;
        }
    }
    picture {
        @apply flex;
    }
    img {
        @apply max-w-none w-full object-cover transition-all hover:scale-105;
    }
    [data-content-type="text"] {
        @apply mb-[30px] lg:px-[45px];
    }
}
