#html-body .offer-section[data-content-type="row"] {
    @apply py-[30px] md:pt-[120px] md:pb-[160px];

    h1 {
        @apply mb-[30px];
    }

    h2 {
        @apply mb-[24px] mt-[20px];
    }

    p {
        @apply mb-[24px];
    }

    ol {
        li {
            @apply mb-[15px] pl-[20px] -indent-[20px];
        }
    }

    [data-content-type=buttons] {
        @apply mb-[10px];
    }


    .pagebuilder-column-line {
        @apply md:gap-[10rem];
    }

    .pagebuilder-column {
        &:has([data-content-type="image"]) {
            @apply max-md:order-first;
        }
    }

    img {
        @apply max-md:w-full max-md:max-w-none;
    }
}
