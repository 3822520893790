[type='checkbox'] {
    @apply bg-transparent w-[22px] h-[22px] rounded border-silver-sand;
    &:checked {
        @apply bg-flush-mahogany border-flush-mahogany;
    }
    &:hover {
        @apply shadow-[0_0_4px_0_rgba(0,0,0,0.16)];
    }
    & ~ * {
        @apply text-blue-charcoal no-underline;
    }
}
[type='radio'] {
    @apply bg-transparent w-[22px] h-[22px] rounded-[22px] border-silver-sand;
    &:checked {
        @apply bg-flush-mahogany border-flush-mahogany;
    }
    &:hover {
        @apply shadow-[0_0_4px_0_rgba(0,0,0,0.16)];
    }
    & ~ * {
        @apply text-blue-charcoal no-underline;
    }
}
