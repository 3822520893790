.stepbystep-index-post {
    .category-banner {
        .category-banner-col {
            &.category-banner-media {
                @apply z-[41];
            }
        }
        p {
            @apply mt-[30px] font-[600];
        }
    }
    .cms-page-view {
        @apply mt-[120px];
        p {
            strong {
                @apply bg-flush-mahogany text-white inline-block w-[25px] text-center rounded-full mb-[20px];
            }
        }
        h3 {
            @apply mb-[20px];
        }
        .pagebuilder-column-line {
            @apply mb-[40px] mt-[40px] w-[100%] flex;
            .pagebuilder-column {
                &:first-child {
                    @apply w-[25%] basis-auto;
                }
                &:last-child {
                    @apply w-[75%] basis-auto;
                }
            }
        }
    }
}
