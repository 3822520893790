.storelocator-index-index {
    .page-wrapper {
        @apply pt-[80px] md:pt-[80px];
    }
    .columns {
        @apply xl:max-w-[960px]
    }
    .page-title {
        @apply mt-[50px] md:mt-[160px] mb-[40px] md:mb-[60px] max-w-[460px];
    }
}
.storelocator-index-index,
.catalog-product-view {
    .bh-sl-map-container {
        @apply h-[925px] max-md:h-[450px] mb-[33px];
        #bh-sl-map {
            @apply h-[925px] max-md:h-[450px];
        }
    }
    #bh-sl-user-location {
        @apply p-[24px] mb-[33px];
        #bh-sl-address {
            @apply mb-[20px];
        }
    }
    #bh-sl-maxdistance {
        @apply h-[4px];
    }
    .location-list {
        li {
            @apply p-[24px] mb-[8px] border border-transparent;
            &.list-focus {
                @apply border-silver-sand;
            }
            dl {
                dt {
                    @apply inline-block w-[80px] text-nevada;
                }
                dd {
                    @apply inline-block;
                }
            }
        }
    }
    .loc-dist, .navigate {
        @apply float-right max-md:float-none;
    }
}

.location-list {
    @apply list-reset-styles;
}

.locations-list-container {
    @apply min-h-[820px] sm:min-h-[615px] md:min-h-[445px];
}
