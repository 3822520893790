/**
 * different styles can be found at https://tailwindcss-forms.vercel.app/
 **/
.form-input,
.form-email,
.form-select,
.form-multiselect,
.form-textarea {
    @apply min-h-a11y;

    &:focus {
        @apply ring ring-primary ring-opacity-50;
    }
}
