@layer utilities {
    .clip-hero {
        clip-path: polygon(0 0, 100% 0, 39vw 100%, 0 100%);
    }
    .bg-gradient-hero {
        background: linear-gradient(180deg, #08101A 0%, rgba(8, 16, 26, 0) 80.7%);
    }
}
#html-body .hero-section[data-content-type="row"] {
    @apply block max-md:pb-[30px];
    .pagebuilder-column-group {
        @apply md:flex md:flex-col md:items-stretch lg:min-h-[750px] md:py-[80px] md:relative;
    }
    .pagebuilder-column-line {
        @apply md:container md:flex-[1_1_auto] md:flex md:flex-col md:items-stretch md:justify-center gap-8;
    }
    .pagebuilder-column {
        &:first-child {
            @apply relative md:absolute md:h-full md:left-0 md:top-0 md:w-[54%] md:flex-[1_1_auto] after:content-[''] after:absolute after:z-10 after:inset-0 after:bg-gradient-hero md:after:clip-hero after:opacity-60;
        }
        &:nth-child(2) {
            @apply md:w-[46%] md:ml-auto lg:pl-[150px] max-md:container;
        }

    }
    img {
        @apply md:clip-hero md:absolute md:h-full w-full md:left-0 md:top-0 max-w-none md:object-cover md:object-center;
    }
}

#html-body.dwd_news-category-view .hero-section[data-content-type="row"] {
    .pagebuilder-column-group {
        @apply lg:min-h-[625px];
    }
}

