/* purgecss start ignore */
@import "tailwindcss/base";
@import "tailwindcss/components";

@import url(components/category-page.css);
@import url(components/product-prices.css);
@import url(components/cart.css);
@import url(components/customer.css);
@import url(components/forms.css);
@import url(components/messages.css);
@import url(components/product-list.css);
@import url(components/product-page.css);
@import url(components/wishlist.css);
@import url(components/modal.css);
@import url(components/slider.css);
@import url(components/structure.css);
@import url(components/swatches.css);
@import url(components/button.css);
@import url(components/theming.css);
@import url(components/transitions.css);
@import url(components/typography.css);
@import url(components/page-builder.css);

/* purgecss end ignore */

@import "tailwindcss/utilities";
@import "theme.css";

/*override Snowdog_Menu styles*/
@layer utilities {
    .snowdog-menu-text-hover {
        /*@apply text-blue-700;*/
    }
}
.snowdog-menu-link {
    /*@apply font-medium leading-6 hover:snowdog-menu-text-hover group-hover:snowdog-menu-text-hover;*/
}
.snowdog-menu-link-topmenu-mobile {
    /*@apply block px-8 py-4;*/
}
.hero-image {
    /*@apply mt-0;*/
}
