#html-body .video-banner-section[data-content-type="row"] {
    @apply relative min-h-screen pt-[270px] pb-[50px] md:py-[80px] lg:pl-[160px] 2xl:px-[160px] max-md:text-right
    before:content-[''] before:absolute before:z-10 before:inset-0 before:bg-gradient-to-r before:from-blue-charcoal before:to-transparent
    after:content-[''] after:absolute after:z-10 max-md:after:max-h-[420px] max-md:after:bottom-0  md:after:top-0 after:right-0 max-md:after:max-w-[145px] max-md:after:w-full  md:after:w-[31%] after:h-full after:bg-triangale-red-sm md:after:bg-trapezoid after:bg-no-repeat after:bg-[length:100%_100%] after:mix-blend-multiply;

    [data-element="inner"] {

    }
    [data-content-type="text"] {
        @apply max-w-[525px] max-md:ml-auto;
    }
    [data-content-type] {
        &:not([data-content-type="html"]) {
            @apply relative z-20;
        }
    }
    [data-content-type="buttons"] {
        @apply mt-[35px];
    }
    h1 {
        @apply text-[72px] lg:text-[10rem] mb-[25px];
        &:has(.content-span) {
            @apply font-family__heading-extra uppercase;
        }
    }
}
