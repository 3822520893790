#html-body .features-section[data-content-type="row"] {
    @apply pt-[80px] pb-[5px] md:pt-[160px] md:pb-[145px] max-md:text-center;
    .pagebuilder-column {
        @apply  max-md:mb-[30px];
    }
    [data-content-type="html"] {
        @apply mb-[15px];
        svg {
            @apply h-[32px] max-md:mx-auto;
        }
    }
    h1, h2, h5 {
        @apply mb-[20px] md:mb-[80px];
    }
    h3 {
        @apply text-[18px] md:text-[24px];
    }
    p {
        @apply max-md:text-sm;
    }
}
